import { ref } from '@vue/composition-api'

export default function handleFormData() {
  const formData = ref(null)

  const setFormData = data => {
    const tempFormData = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(ele => {
          tempFormData.append(`${key}[]`, ele ?? '')
        })
      } else {
        tempFormData.append(key, value ?? '')
      }
    })
    formData.value = tempFormData
  }

  return {
    formData,
    setFormData,
  }
}
