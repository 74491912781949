<template>
  <div
    v-if="file"
    class="mb-2"
  >
    <b-link
      v-if="isFileURL"
      :href="`https://docs.google.com/viewerng/viewer?url=${file}`"
      target="_blank"
    >
      View PDF
    </b-link>

    <b-link
      v-else
      :href="fileBlob"
      target="_blank"
    >
      View PDF
    </b-link>
  </div>
</template>
<script>
export default {
  name: 'FilesPreview',
  props: {
    file: { type: [String, File], default: '' },
  },
  data() {
    return {
      fileBlob: '',
    }
  },
  computed: {
    isFileURL() {
      return typeof this.file === 'string'
    },
  },
  watch: {
    file() {
      this.setFileBlob()
    },
  },
  beforeDestroy() {
    this.clearBlobs()
  },
  methods: {
    setFileBlob() {
      this.fileBlob = URL.createObjectURL(this.file)
      return this.fileBlob
    },
    clearBlobs() {
      URL.revokeObjectURL(this.fileBlob)
    },
  },
}
</script>
<style lang="">

</style>
