<template>
  <validation-observer #default="{handleSubmit}">
    <b-form @submit.prevent>
      <fieldset :disabled="disabled">
        <b-form-row>

          <b-col
            cols="12"
            class="border p-2 my-2"
          >
            <h4>Event Title</h4>
            <b-row>
              <!-- Title -->
              <b-col>
                <text-input
                  id="title"
                  name="Title"
                  rules="required|min:3|max:200"
                  :text.sync="event.eventName"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-modal
            id="reason-modal"
            centered
            title="Reject Reason"
            hide-footer
          >
            <reason-modal
              @submit-reason="handleDecline"
            />
          </b-modal>
          <b-col
            cols="12"
            class="border p-2 my-2"
          >
            <h4>Time &amp; Location</h4>
            <b-row>
              <!-- Publish Start Date -->
              <b-col md="6">
                <date-time
                  id="publish-start-date"
                  :datetime.sync="event.publishDate"
                  name="Publish Start Date"
                  rules="required"
                  :config="{ enableTime:true,dateFormat: 'Y-m-d H:i',minDate:'today',maxDate:event.endDate}"
                />
              </b-col>
              <!-- Start Datetime -->
              <b-col md="6">
                <date-time
                  id="start-datetime"
                  :datetime.sync="event.startDate"
                  name="Start Date"
                  rules="required"
                  :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
                />
              </b-col>
              <!-- End Datetime -->
              <b-col md="6">
                <date-time
                  id="end-datetime"
                  :datetime.sync="event.endDate"
                  name="End Date"
                  :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: event.startDate}"
                />
              </b-col>
              <!-- Venue Name -->
              <b-col md="6">
                <text-input
                  id="venue-name"
                  name="Venue Name"
                  rules="min:3|max:200"
                  :text.sync="event.venueName"
                />
              </b-col>
              <!-- Venue Link URL -->
              <b-col md="6">
                <text-input
                  id="venue-link"
                  name="Venue Link"
                  rules="url"
                  :text.sync="event.venueLink"
                />
              </b-col>

              <!-- Venue Address -->
              <b-col
                cols="12"
                class="mt-1"
              >
                <h3 class="mb-2">
                  Address
                </h3>
                <location-inputs :details="event" />
              </b-col>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            class="border p-2"
          >
            <h4>Media</h4>
            <b-row>
              <!-- Image -->
              <b-col
                cols="12"
              >
                <upload-files
                  id="image"
                  :disabled="disabled"
                  label="Upload Image"
                  rules="ext:png,jpeg,jpg|size:10240"
                  accept="image/png, image/jpeg"
                  :cropper="true"
                  :stencil-props="{
                    aspectRatio: 2/1,
                  }"
                  :files.sync="event.image"
                />
                <div>
                  <images-preview
                    :images.sync="event.image"
                  />
                </div>
                <div
                  class="mb-2"
                >
                  <b-form-row class="text-warning mt-2">
                    <b-col md="3">
                      <li>Image ratio: 2/1</li>
                    </b-col>
                    <b-col md="3">
                      <li>Maximum file size: 10MB</li>
                    </b-col>
                    <b-col md="3">
                      <li>Supported image files: JPEG or PNG</li>
                    </b-col>
                  </b-form-row>
                </div>
              </b-col>

              <!-- Attachment -->
              <b-col cols="12">
                <upload-files
                  id="attachments"
                  :disabled="disabled"
                  label="Upload Attachment"
                  accept="application/pdf"
                  rules="ext:pdf|size:3145728"
                  :files.sync="attachmentProxy"
                />

                <file-preview
                  :file="event.brochure[0]"
                  :is-edit-form="isEditForm"
                />
              </b-col>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            class="border p-2 my-2"
          >
            <h4>Booking Details</h4>
            <b-row>
              <!-- Target Audience -->
              <b-col md="6">
                <custom-vue-select
                  id="target-audience"
                  label="Target Audience"
                  placeholder="Please select target audience"
                  :value-reducer="option=>option.value"
                  :options="[
                    {text:'Both',value:'both'},
                    {text:'Male',value:'male'},
                    {text:'Female',value:'female'}
                  ]"
                  :selected.sync="event.targetAudience"
                  text-field="text"
                  :disabled="disabled"
                />
              </b-col>
              <!-- Attendees Capacity -->
              <b-col md="6">
                <text-input
                  id="attendees-capacity"
                  name="Attendees Capacity"
                  type="number"
                  rules="numeric"
                  :text.sync="event.attendeesCapacity"
                />
              </b-col>
              <!-- Presence -->
              <b-col md="6">
                <custom-vue-select
                  id="presence"
                  label="Presence"
                  placeholder="Select presence"
                  :value-reducer="option=>option.value"
                  :options="[
                    {text:'Both',value:'both'},
                    {text:'Online',value:'online'},
                    {text:'Offline',value:'offline'},
                  ]"
                  :selected.sync="event.presenceType"
                  text-field="text"
                  :disabled="disabled"
                />
              </b-col>
              <!-- Online Event Link URL  -->
              <b-col md="6">
                <text-input
                  v-if="['online','both'].includes(event.presenceType)"
                  id="online-event-link-url"
                  name="Online Event Link URL"
                  rules="url"
                  :text.sync="event.onlineEventLink"
                />
              </b-col>
              <!-- Price Note -->
              <b-col md="6">
                <text-input
                  id="price-note"
                  name="Price Note"
                  rules="min:3|max:200"
                  :text.sync="event.price"
                />
              </b-col>
              <!-- Registration Link -->
              <b-col md="6">
                <text-input
                  id="registration-link"
                  name="Registration Link"
                  rules="url"
                  :text.sync="event.bookingLink"
                />
              </b-col>
              <!-- Restriction Notes -->
              <b-col cols="12">
                <text-area-input
                  id="restriction-notes"
                  rules="max:300"
                  name="Restriction Notes"
                  :text.sync="event.restrictionNotes"
                />
              </b-col>
            </b-row>
          </b-col>

          <b-col
            cols="12"
            class="border p-2 my-2"
          >
            <h4>Organizer Details</h4>
            <b-row>
              <!-- Organizer Name -->
              <b-col md="6">
                <text-input
                  id="organizer-name"
                  name="Organizer Name"
                  rules="min:3|max:200"
                  :text.sync="event.organizer"
                />
              </b-col>
              <!-- Organizer Link -->
              <b-col md="6">
                <text-input
                  id="organizer-link"
                  name="Organizer Link"
                  rules="url"
                  :text.sync="event.organizerLink"
                />
              </b-col>
              <!-- Contact Details -->
              <b-col md="6">
                <text-input
                  id="contact-details"
                  name="Contact Details"
                  rules="min:3|max:200"
                  :text.sync="event.contactDetails"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="facebook"
                  name="Facebook"
                  :text.sync="event.facebook"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="twitter"
                  name="Twitter"
                  :text.sync="event.twitter"
                />
              </b-col>
              <b-col md="6">
                <text-input
                  id="instagram"
                  name="Instagram"
                  :text.sync="event.instagram"
                />
              </b-col>
            </b-row>
          </b-col>

          <!-- Custom Section -->
          <b-col
            cols="12"
            class="border p-2 my-2"
          >
            <h4>Custom Section</h4>
            <!-- Title -->
            <b-form-row>
              <b-col>
                <text-input
                  id="custom-section-title"
                  name="Title"
                  rules="max:200"
                  :text.sync="event.customSectionKey"
                />
              </b-col>
              <!-- Description -->
              <b-col cols="12">
                <text-area-input
                  id="custom-section-description"
                  name="Description"
                  rules="max:800"
                  :text.sync="event.customSectionValue"
                />
              </b-col>
            </b-form-row>
          </b-col>

          <b-col
            cols="12"
            class="border p-2 my-2"
          >
            <h4>More Details</h4>
            <b-row>
              <!-- Event Source -->
              <b-col md="6">
                <text-input
                  id="event-source"
                  name="Event Source"
                  rules="min:3|max:200"
                  :text.sync="event.eventSource"
                />
              </b-col>
              <!-- Event Source Url -->
              <b-col md="6">
                <text-input
                  id="event-source-url"
                  name="Event Source URL"
                  rules="url"
                  :text.sync="event.eventDetailsLink"
                />
              </b-col>
              <!-- Event Category -->
              <b-col md="6">
                <event-type-select
                  :disabled="disabled"
                  :event-type.sync="event.eventCategory"
                />
              </b-col>
              <!-- Source Category -->
              <b-col md="6">
                <text-input
                  id="source-category"
                  name="Source Category"
                  rules="min:3|max:200"
                  :text.sync="event.sourceCategory"
                />
              </b-col>

              <!-- Speaker -->
              <b-col md="6">
                <text-input
                  id="speaker"
                  name="Speaker"
                  rules="min:3|max:200"
                  :text.sync="event.speaker"
                />
              </b-col>

              <!-- Languages Select -->
              <b-col md="6">
                <language-select
                  :disabled="disabled"
                  :languages.sync="event.language"
                  :is-multiple="false"
                />
              </b-col>

              <!-- General Notes -->
              <b-col cols="12">
                <rich-text-editor
                  id="general-notes"
                  name="General Notes"
                  rules="max:1000"
                  :disabled="disabled"
                  :description.sync="event.generalNotes"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col
            cols="12"
            class="mt-1"
          >
            <b-form-row>
              <!-- Mark Attending -->
              <b-col>
                <b-checkbox
                  v-model="event.markedAttending"
                  switch
                >
                  Enable mark attending for mobile users
                </b-checkbox>
              </b-col>
              <!-- Event Status -->
              <b-col>
                <b-form-group
                  label="Status"
                  label-for="status"
                >
                  <b-radio-group
                    id="status"
                    v-model="event.status"
                    :options="[
                      {text:'Active',value:true},
                      {text:'Inactive',value:false}
                    ]"
                  />
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-col>
        </b-form-row>

      </fieldset>
      <div
        class="text-right"
      >
        <back class="mr-1" />

        <template v-if="event.suggestion_status==='pending'">
          <b-button
            class="mr-1"
            variant="danger"
            @click="showDeclineModal"
          >
            Decline
          </b-button>
          <b-button
            variant="primary"
            class="mr-1"
            @click="toggleSuggest('approved')"
          >
            Approve
          </b-button>
        </template>

        <submit-button
          v-if="!disabled"
          v-bind="{submit,handleSubmit}"
        />
      </div>
    </b-form>
  </validation-observer>
</template>
<script>
import Events from '@/common/compositions/QuickEvents/quickEventApi'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import ReasonModal from '@/common/components/Offers/ReasonModal.vue'
import Back from '../common/FormInputs/Back.vue'
import CustomVueSelect from '../common/FormInputs/CustomVueSelect.vue'
import DateTime from '../common/FormInputs/DateTime.vue'
import FilePreview from '../common/FormInputs/FilePreview.vue'
import ImagesPreview from '../common/FormInputs/ImagesPreview.vue'
import LanguageSelect from '../common/FormInputs/LanguageSelect.vue'
import LocationInputs from '../common/FormInputs/LocationInputs.vue'
import RichTextEditor from '../common/FormInputs/RichTextEditor.vue'
import SubmitButton from '../common/FormInputs/SubmitButton.vue'
import TextAreaInput from '../common/FormInputs/TextAreaInput.vue'
import TextInput from '../common/FormInputs/TextInput.vue'
import UploadFiles from '../common/FormInputs/UploadFiles.vue'
import EventTypeSelect from '../QuickEvents/EventTypeSelect.vue'

export default {
  name: 'CommunityEventForm',
  components: {
    CustomVueSelect,
    TextInput,
    DateTime,
    EventTypeSelect,
    LocationInputs,
    LanguageSelect,
    SubmitButton,
    TextAreaInput,
    ImagesPreview,
    UploadFiles,
    RichTextEditor,
    Back,
    FilePreview,
    ReasonModal,
  },
  props: {
    event: { type: Object, default: () => {} },
    submit: { type: Function, default: null },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      toggleData: {},
    }
  },
  computed: {
    isEditForm() {
      return !!this.$route.params.id
    },
    attachmentProxy: {
      get() {
        return this.event.brochure
      },
      set(value) {
        this.$set(this.event, 'brochure', value)
      },
    },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { listSuggestedEventsRequest, toggleSuggestedEventsRequest } = Events()
    return { listSuggestedEventsRequest, toggleSuggestedEventsRequest, successfulOperationAlert }
  },
  methods: {
    toggleSuggest(newStatus) {
      this.toggleData.suggest_status = newStatus
      this.toggleSuggestedEventsRequest(this.event.id, this.toggleData).then(() => {
        const message = newStatus === 'approved' ? 'Event is approved successfully' : 'Event is declined successfully'
        this.successfulOperationAlert(message)
        this.event.suggestion_status = newStatus
      })
    },
    showDeclineModal() {
      this.$bvModal.show('reason-modal')
    },
    handleDecline(reason) {
      this.toggleData.reject_reason = reason
      this.toggleSuggest('decline')
      this.$bvModal.hide('reason-modal')
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
