<template>
  <validation-provider
    #default="validationContext"
    name="Event Types"
    :rules="rules"
  >
    <b-form-group
      label-for="event-type"
    >
      <template #label>

        <span
          :class="{required:JSON.stringify(rules).includes('required')}"
        >
          Event Category
        </span>

      </template>
      <v-select
        id="event-type"
        v-model="tempEventType"
        :multiple="$attrs.multiple"
        :reduce="event => event.id"
        placeholder="Event category"
        label="title"
        :options="eventTypeOptions"
        :disabled="disabled"
        :close-on-select="!$attrs.multiple"
      />
      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  name: 'EventTypeSelect',
  components: {
    vSelect,
  },
  props: {
    eventType: { type: [Array, Number], default: () => [] },
    rules: { type: String, default: '' },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      eventTypeOptions: [],
    }
  },
  computed: {
    tempEventType: {
      get() {
        return this.eventType
      },
      set(value) {
        this.$emit('update:eventType', value)
      },
    },
  },
  setup() {
    const { getValidationState } = formValidation()
    return { getValidationState }
  },
  created() {
    this.getEventTypes()
  },
  methods: {
    getEventTypes() {
      this.$activities.get('/internalops/event/types?order=asc&status=active&paginated=false').then(res => {
        this.eventTypeOptions = res.data.data
      }).catch(() => [])
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
