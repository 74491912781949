<template>
  <validation-provider
    #default="validationContext"
    name="Language"
    :rules="rules"
  >
    <b-form-group
      label-for="language"
    >
      <template #label>
        <span
          :class="{required:JSON.stringify(rules).includes('required')}"
        >
          Language
        </span>

      </template>
      <v-select
        id="language"
        :value="languages"
        :multiple="isMultiple"
        :reduce="lang => lang.id"
        label="language_name"
        :options="languagesOptions"
        :disabled="disabled"
        :close-on-select="!isMultiple"
        @input="onInput"
      />
      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import vSelect from 'vue-select'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  name: 'LanguageSelect',
  components: {
    vSelect,
  },
  props: {
    languages: { type: [Array, Number], default: () => [] },
    rules: { type: String, default: '' },
    isMultiple: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      languagesOptions: [],
    }
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()
    return {
      getValidationState,
    }
  },
  created() {
    this.getLanguages()
  },
  methods: {
    getLanguages() {
      this.$entities.get('internalops/languages?paginated=false').then(res => {
        this.languagesOptions = res.data.data
      })
    },
    onInput(languages) {
      this.$emit('update:languages', languages)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
