<template>
  <b-form>
    <text-input
      v-if="reasonOnly"
      id="reason"
      name=""
      placeholder="No Rejection Reason Was Provided"
      :text="givenReason"
      disabled
    />
    <text-input
      v-else
      id="reason"
      name=""
      :placeholder="'Reason (optional)'"
      :text.sync="reason"
    />
    <div class="text-right">
      <b-button
        v-if="!reasonOnly"
        variant="primary"
        type="submit"
        @click.prevent="submit(true)"
      >Submit</b-button>
      <b-button
        variant="secondary"
        class="ml-2"
        @click="submit(false)"
      >Back</b-button>
    </div>
  </b-form>
</template>

<script>
import TextInput from '../common/FormInputs/TextInput.vue'

export default {
  components: {
    TextInput,
  },
  props: {
    reasonOnly: { type: Boolean, default: () => false },
    givenReason: { type: String, default: () => '' },
  },
  data() {
    return {
      reason: '',
    }
  },
  methods: {
    submit(send) {
      if (send) this.$emit('submit-reason', this.reason)
      this.$bvModal.hide('reason-modal')
    },
  },
}
</script>
