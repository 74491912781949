import axiosInstances from '@/libs/axios-instances'
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import handleFormData from '../common/handleFormData'
import uploadPercentage from '../common/uploadPercentage'

export default function QuickEvent() {
  const { formData, setFormData } = handleFormData()
  const { calculateUploadPercentage, progress } = uploadPercentage()

  const event = ref({
    organizationId: store.getters['mainEntity/getEntityId'],
    organizerUserId: store.getters['auth/getUserData'].id,
    contactName: `${store.getters['auth/getUserData'].profile?.first_name} ${store.getters['auth/getUserData'].profile?.last_name}`,
    contactNumber: store.getters['auth/getUserData'].profile?.phone,
    location: {},
    eventDates: {},
    activateBeforeHours: 0,
    frequency: 'once',
    brochure: [],
    image: [],
    visibility: 'private',
    singleSession: true,
  })

  const formattedEvent = computed(() => ({
    organizationId: event.value.organizationId,
    organizerUserId: event.value.organizerUserId,
    types: [event.value.types],
    name: event.value.name,
    presenceType: event.value.presenceType,
    frequency: event.value.frequency,
    activateBeforeHours: event.value.activateBeforeHours,
    generalNotes: event.value.generalNotes,
    brochure: event.value.brochure[0],
    image: event.value.image[0],
    contactNumber: event.value.contactNumber,
    contactName: event.value.contactName,
    expectedTicketsAmount: event.value.expectedTicketsAmount,
    expectedAttendeesNumber: event.value.expectedAttendeesNumber,
    visibility: event.value.visibility,
    singleSession: event.value.singleSession,
    ...(
      event.value.eventDates ? {
        ...event.value.eventDates,
        days: event.value.frequency === 'weekly' ? event.value.eventDates.weekDates : event.value.eventDates.days,
      } : {
        startDate: event.value.startTime,
        endDate: event.value.endTime,
      }),
    ...(event.value.presenceType !== 'online' ? event.value.location : {}),
  }))

  const getEventRequest = id => axiosInstances.activities.get(`internalops/events/${id}`).then(res => res)

  const addEventRequest = () => {
    setFormData(formattedEvent.value)

    return axiosInstances.activities.post('internalops/events', formData.value, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: progressEvent => { calculateUploadPercentage(progressEvent) },
    }).then(res => res)
  }

  const editEventRequest = id => {
    setFormData(formattedEvent.value)

    return axiosInstances.activities.post(`internalops/events/${id}?_method=PUT`, formData.value, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: progressEvent => { calculateUploadPercentage(progressEvent) },
    }).then(res => res)
  }

  const listSuggestedEventsRequest = params => axiosInstances.activities.get('/internalops/suggest-events', { params }).then(res => res)

  const toggleSuggestedEventsRequest = (eventId, params) => axiosInstances.activities.post(`/internalops/events/${eventId}/suggest-status`, params).then(res => res)

  const getEventStatistics = () => axiosInstances.activities.get('/internalops/events-statistics', { headers: { Accept: 'application/json, text/plain, */*' } }).then(res => res)

  return {
    event, progress, addEventRequest, editEventRequest, getEventRequest, listSuggestedEventsRequest, toggleSuggestedEventsRequest, getEventStatistics,
  }
}
