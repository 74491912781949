<template>
  <b-card header="Add Community Event">
    <community-event-form
      :event="event"
      :submit="addEvent"
    />
  </b-card>
</template>
<script>
import CommunityEventForm from '@/common/components/CommunityEvents/CommunityEventForm.vue'
import communityEvent from '@/common/compositions/CommunityEvents/communityEventApi'

export default {
  name: 'AddCommunityEvent',
  components: { CommunityEventForm },
  setup() {
    const { event, addCommunityEvent } = communityEvent()
    return { event, addCommunityEvent }
  },
  methods: {
    addEvent() {
      return this.addCommunityEvent().then(() => {
        this.$router.push({ name: 'community-events-list' })
      })
    },
  },
}
</script>
<style lang="">

</style>
