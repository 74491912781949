import { computed, ref } from '@vue/composition-api'
import { saveAs } from 'file-saver'
import Vue from 'vue'
import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '../common/handleAlerts'
import handleFormData from '../common/handleFormData'

const { confirmOperation, successfulOperationAlert, successfulDeletionAlert } = handleAlerts()
const { formData, setFormData } = handleFormData()

export default function communityEvent() {
  const event = ref({
    markedAttending: true,
    status: false,
    publishDate: Vue.prototype.$moment().format('YYYY-MM-DD HH:mm'),
    image: [],
    brochure: [],
  })

  const eventFormatted = computed(() => ({
    ...event.value, eventCategory: event.value.eventCategory?.id || event.value.eventCategory, image: event.value.image[0], brochure: event.value.brochure[0], language: event.value.language?.id || event.value.language,
  }))

  const listCommunityEvents = params => axiosInstances.activities.get('/internalops/scrapped/event', {
    params,
  }).then(res => res)

  const listAttendees = (id, params) => axiosInstances.activities.get(`/internalops/scrapped/event/${id}/attendees`, {
    params,
  }).then(res => res)

  const listFeedbackReports = (id, params) => axiosInstances.activities.get(`/internalops/scrapped/event/${id}/report`, {
    params,
  }).then(res => res)

  const getCommunityEvent = id => axiosInstances.activities.get(`/internalops/scrapped/event/${id}`).then(res => {
    event.value = { ...res.data.data, image: [res.data.data.image].filter(image => image), brochure: [res.data.data.brochure] }
    return res.data.data
  })

  const getOriginalCommunityEvent = id => axiosInstances.activities.get(`/internalops/suggest-events/event/${id}`).then(res => {
    event.value = { ...res.data.data, image: [res.data.data.image].filter(image => image), brochure: [res.data.data.brochure] }
    return res.data.data
  })

  const addCommunityEvent = () => {
    setFormData(eventFormatted.value)

    return axiosInstances.activities.post('/internalops/scrapped/event', formData.value).then(() => {
      successfulOperationAlert('Community event is added successfully')
    })
  }

  const editCommunityEvent = id => {
    setFormData(eventFormatted.value)

    return axiosInstances.activities.post(`/internalops/scrapped/event/${id}?_method=PUT`, formData.value).then(() => {
      successfulOperationAlert('Community event is updated successfully')
    })
  }

  const toggleStatus = selectedEvent => axiosInstances.activities.post(`/internalops/scrapped/event/${selectedEvent.id}/status`).then(() => {
    const operationType = selectedEvent.status ? 'Deactivated' : 'Activated'
    selectedEvent.status = !selectedEvent.status
    successfulOperationAlert(`Event is ${operationType} successfully`)
  })

  const toggleReportStatus = (eventId, selectedReport) => axiosInstances.activities.post(`/internalops/scrapped/event/${eventId}/report/${selectedReport.id}`).then(() => {
    selectedReport.readAt = !selectedReport.readAt
    const message = selectedReport.readAt ? 'Read status is set successfully' : 'Read status is removed successfully'
    successfulOperationAlert(message)
  })

  const deleteCommunityEventRequest = id => axiosInstances.activities.delete(`/internalops/scrapped/event/${id}`).then(() => {
    successfulDeletionAlert('Community event is deleted successfully')
  })

  const deleteCommunityEvent = id => confirmOperation().then(() => deleteCommunityEventRequest(id))

  const exportEvent = id => axiosInstances.activities.get(`/internalops/scrapped/event/${id}/export`, {
    responseType: 'blob',
  }).then(res => {
    successfulOperationAlert('Event is exported successfully')

    const blob = new Blob([res.data])
    saveAs(blob, 'community-events.xlsx')
  })

  return {
    event, listCommunityEvents, getCommunityEvent, addCommunityEvent, editCommunityEvent, deleteCommunityEvent, toggleStatus, exportEvent, listAttendees, listFeedbackReports, toggleReportStatus, getOriginalCommunityEvent,
  }
}
